exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-email-unsubscribe-tsx": () => import("./../../../src/pages/email/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-email-unsubscribe-tsx" */),
  "component---src-pages-email-verification-error-tsx": () => import("./../../../src/pages/email/verification/error.tsx" /* webpackChunkName: "component---src-pages-email-verification-error-tsx" */),
  "component---src-pages-email-verification-success-tsx": () => import("./../../../src/pages/email/verification/success.tsx" /* webpackChunkName: "component---src-pages-email-verification-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institution-demo-tsx": () => import("./../../../src/pages/institution/demo.tsx" /* webpackChunkName: "component---src-pages-institution-demo-tsx" */),
  "component---src-pages-institution-sign-up-tsx": () => import("./../../../src/pages/institution/sign-up.tsx" /* webpackChunkName: "component---src-pages-institution-sign-up-tsx" */),
  "component---src-pages-institution-start-phase-tsx": () => import("./../../../src/pages/institution/start-phase.tsx" /* webpackChunkName: "component---src-pages-institution-start-phase-tsx" */),
  "component---src-pages-institution-tsx": () => import("./../../../src/pages/institution.tsx" /* webpackChunkName: "component---src-pages-institution-tsx" */),
  "component---src-pages-legal-b-2-b-privacy-policy-tsx": () => import("./../../../src/pages/legal/b2b-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-b-2-b-privacy-policy-tsx" */),
  "component---src-pages-legal-legal-notice-tsx": () => import("./../../../src/pages/legal/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-legal-notice-tsx" */),
  "component---src-pages-legal-refund-tsx": () => import("./../../../src/pages/legal/refund.tsx" /* webpackChunkName: "component---src-pages-legal-refund-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-pilot-phase-tsx": () => import("./../../../src/pages/pilot-phase.tsx" /* webpackChunkName: "component---src-pages-pilot-phase-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-researcher-tsx": () => import("./../../../src/pages/researcher.tsx" /* webpackChunkName: "component---src-pages-researcher-tsx" */),
  "component---src-pages-student-get-started-tsx": () => import("./../../../src/pages/student/get-started.tsx" /* webpackChunkName: "component---src-pages-student-get-started-tsx" */),
  "component---src-pages-teacher-tsx": () => import("./../../../src/pages/teacher.tsx" /* webpackChunkName: "component---src-pages-teacher-tsx" */),
  "component---src-pages-training-center-tsx": () => import("./../../../src/pages/training-center.tsx" /* webpackChunkName: "component---src-pages-training-center-tsx" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-en-edhec-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/en/edhec.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-en-edhec-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-en-sup-biotech-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/en/sup-biotech.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-en-sup-biotech-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-en-university-of-genoa-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/en/university-of-genoa.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-en-university-of-genoa-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-en-university-of-modena-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/en/university-of-modena.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-en-university-of-modena-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-fr-edhec-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/fr/edhec.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-fr-edhec-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-fr-sup-biotech-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/fr/sup-biotech.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-fr-sup-biotech-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-fr-university-of-genoa-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/fr/university-of-genoa.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-fr-university-of-genoa-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-fr-university-of-modena-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/fr/university-of-modena.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-fr-university-of-modena-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-it-edhec-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/it/edhec.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-it-edhec-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-it-university-of-genoa-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/it/university-of-genoa.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-it-university-of-genoa-md" */),
  "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-it-university-of-modena-md": () => import("./../../../src/templates/customer-success-story.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/success-stories/it/university-of-modena.md" /* webpackChunkName: "component---src-templates-customer-success-story-tsx-content-file-path-src-contents-success-stories-it-university-of-modena-md" */),
  "component---src-templates-documentation-tsx-content-file-path-src-contents-documentation-en-anonymized-mode-md": () => import("./../../../src/templates/documentation.tsx?__contentFilePath=/Users/adrien/Programming/Amanote/website/amanote-website/src/contents/documentation/en/anonymized-mode.md" /* webpackChunkName: "component---src-templates-documentation-tsx-content-file-path-src-contents-documentation-en-anonymized-mode-md" */)
}

