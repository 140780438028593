/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/* analytics.js */
import Analytics from 'analytics';
import googleTagManager from '@amaplex-software/analytics-google-tag-manager';

let isAnalyticsInitialized = false;

// Mock Analytics before it's loaded.
if (typeof window !== 'undefined')
{
  (window).Analytics = Analytics({});
}

export const analytics = {
  init: () =>
  {
    window.document.addEventListener('scroll', initAnalyticsOnEvent);
    window.document.addEventListener('mousemove', initAnalyticsOnEvent);
    window.document.addEventListener('touchstart', initAnalyticsOnEvent);
    window.document.addEventListener('DOMContentLoaded', () =>
    {
      setTimeout(initGTM, 3500);
    });

    function initAnalyticsOnEvent(event) {
      initGTM();
      event.currentTarget.removeEventListener(event.type, initAnalyticsOnEvent);
    }

    function initGTM()
    {
      if (isAnalyticsInitialized)
      {
        return false;
      }

      isAnalyticsInitialized = true;

      (window).Analytics = Analytics({
        app: 'amanote-website',
        plugins: [
          googleTagManager({
            containerId: 'GTM-NQ3CZKH',
            dataLayerName: 'eventsLayer',
            customScriptSrc: 'https://load.gt.amanote.com/ygbtlbdj.js'
          }),
        ],
      });
    }
  },

  generateB2BLead: async (formId, formData = {}) => {
    const { firstName, lastName, email, ...cleanFormData } = formData;
    (window).Analytics.track('generate_lead', { 'form_id': formId, 'form_data': cleanFormData });
  },
  downloadApp: async (os) => { (window).Analytics.track('app_download', { os }) },
  openWebApp: async () => { (window).Analytics.track('web_app_open') },
  track: async (event, options) => { (window).Analytics.track(event, options) },
};
