module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"efc160233aae8598a9119317e9cebcf7"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr","nl","de","it","es"],"defaultLanguage":"en","siteUrl":"https://amanote.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"returnEmptyString":false},"pages":[{"matchPath":"/changelog","languages":["en"]},{"matchPath":"/legal/(.*)","languages":["en"]},{"matchPath":"/success-stories/(.*)","languages":["en"],"redirect":false},{"matchPath":"/:lang/success-stories/(.*)","getLanguageFromPath":true,"excludeLanguages":["es","en","fr","nl","de","it"],"redirect":false},{"matchPath":"/documentation/(.*)","languages":["en"],"redirect":false},{"matchPath":"/:lang/documentation/(.*)","getLanguageFromPath":true,"excludeLanguages":["es","en","fr","nl","de","it"],"redirect":false}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
